
export const englishTranslation = {
    en: {
        translation: {
            "german": "German",
            "english": "English",
            "Responsive Web-Development": "Responsive Web-Development",
            "High-performance and modern front-end technologies": "High-performance and modern front-end technologies",
            "ReactJS with Gatsby or NextJS and Create-React-App, alternatively Angular 2+ possible": "ReactJS with Gatsby or NextJS and Create-React-App, alternatively Angular 2+ possible",
            "Typescript support, with classes, types and API models. To keep the code clear and concise.": "Typescript support, with classes, types and API models. To keep the code clear and concise.",
            "NPM Dependency Management: Guarantees that the dependencies match. NPM Contributor, automatic tests at CI/CD": "NPM Dependency Management: The dependencies are guaranteed to match. NPM Contributor, automatic tests at CI/CD",
            "Creation of the components with a clear code structure (tree structure). Import, export mechanism of JSON's and other file types. This makes the code clear and easy to understand": "Creation of the components with a clear code structure (tree structure). Import, export mechanism of JSON's and other file types. This makes the code clear and easy to understand",
            "Cloud, Architecture and Microsoft technologies": "Cloud, Architecture and Microsoft technologies",
            "Creation of the appropriate resources, which are inserted into groups": "Creation of the appropriate resources, which are inserted into groups",
            "Asp .NET (Core, 5+), C# API (Azure Functions with Timer, Http, BusService Trigger)": "Asp .NET (Core, 5+), C# API (Azure Functions with Timer, Http, BusService Trigger)",
            "Databases": "Databases",
            "Configuration": "Configuration",
            "cost efficient": "cost efficient",
            "Development of mobile applications": "Development of mobile applications",
            "Technology React Native: Parallel development in iOS and Android": "Technology React Native: Parallel development in iOS and Android",
            "Push notification, remote updates (EXPO, APK auto updates and downloads without google store), storage management, API support, performance considerations": "Push notification, remote updates (EXPO, APK auto updates and downloads without google store), storage management, API support, performance considerations",
            "Material Design, Custom Designs, Animations, Flexible UI Customization, UI Libraries": "Material Design, Custom Designs, Animations, Flexible UI Customization, UI Libraries",
            "Develop native components: Bluetooth, NFC, WIFI, Command Shell and other functions": "Develop native components: Bluetooth, NFC, WIFI, Command Shell and other functions",
            "Fullstack developer with an overview of the entire application": "Fullstack developer with an overview of the entire application",
            "Development can take place in many areas.": "Development can take place in many areas.",
            "The focus is on architecture (Azure, DevOps), .Net C#, React.": "The focus is on architecture (Azure, DevOps), .Net C#, React.",
            "Support is also available in Angular, PHP Laravel and other technologies upon request.": "Support is also available in Angular, PHP Laravel and other technologies upon request.",
            "Results and customer satisfaction are primary for this company": "Results and customer satisfaction are primary for this company",
            "Complete development of applications": "Complete development of applications",
            "The company gladly accepts new as well as existing greenfield projects.": "The company gladly accepts new as well as existing greenfield projects.",
            "We would be happy to advise you on where the problem lies in the application.": "We would be happy to advise you on where the problem lies in the application.",
            "References": "References",
            "DN-Solutions has worked successfully with big-name companies.": "DN-Solutions has worked successfully with big-name companies.",
            "It is always a pleasure to bring even more success to a customer.": "It is always a pleasure to bring even more success to a customer.",
            "Your success is our success.": "Your success is our success.",
            "We have already worked successfully with the following companies:": "We have already worked successfully with the following companies:",
            "Owner and Developer: Ing. Nicholas Grill, BSc.": "Owner and Developer: Ing. Nicholas Grill, BSc.",
            "Nicholas Grill has led several projects to success. He is always looking forward to new challenges.": "Nicholas Grill has led several projects to success. He is always looking forward to new challenges.",
            "Due to his great motivation he can achieve great results for your projects.": "Due to his great motivation he can achieve great results for your projects.",
            "Resume": "Resume",
            "It is also possible to develop for both platforms in Native (Bluetooth, NFC, Shell commands supported with it).": "It is also possible to develop for both platforms in Native (Bluetooth, NFC, Shell commands supported with it).",
            "Mobile applications can be developed in React Native for iOS and Android": "Mobile applications can be developed in React Native for iOS and Android",
            "Architectures, CI/CD, Git, DevOps and Cloud infrastructure: Consulting and construction (Azure)": "Architectures, CI/CD, Git, DevOps and Cloud infrastructure: Consulting and construction (Azure)",
            "Azure resources: Resource groups constructed with e.g. Storage, App Config, App Service (Kudo), App Insights, Logs": "Azure resources: Resource groups constructed with e.g. Storage, App Config, App Service (Kudo), App Insights, Logs",
            "Support and contact": "Support and contact",
            "projects and references": "Projects and References",
            //contact
            "We are happy to help you with your affairs.": "We are happy to help you with your affairs.",
            "Through the experience and passion for software development,": "Through the experience and passion for software development,",
            "we are the ideal partner to solve your software problems.": "we are the ideal partner to solve your software problems.",
            "Data evaluation (table, charts), design, performance or optimization ideas.": "Data evaluation (table, charts), design, performance or optimization ideas.",
            "We have the right answer for you.": "We have the right answer for you.",
            "Where to find us:": "Where to find us:",
            "Company location: Lower Austria / Vienna": "Company location: Lower Austria / Vienna",
            "Telephone number:": "Telephone number:",
            "Direct contact to the chief developer": "Direct contact to the chief developer",
            "Welcome to DN-Solutions contact and support": "Welcome to DN-Solutions contact and support",
            //services
            "We offer you the following services:": "We offer you the following services.",
            "Development: A completely new application will be developed for you from start to finish.": "Development: A completely new application will be developed for you from start to finish.",
            "Architecture / Dev Ops / Agile methods: everything is built for you. Advice is given on which technologies are to be used for the project. From cloud system and hosting, version control system (Git), to frontend or backend creation.": "Architecture / Dev Ops / Agile methods: everything is built for you. Advice is given on which technologies are to be used for the project. From cloud system and hosting, version control system (Git), to frontend or backend creation.",
            "Support & Code Reviews: Problems with your current application? The problem will be analyzed and solved for you.": "Support & Code Reviews: Problems with your current application? The problem will be analyzed and solved for you.",
            "Maintenance: Existing applications are also gladly maintained and kept alive. It is suggested if you should perform a new update for certain libraries/plugins so that the application continues to work properly.": "Maintenance: Existing applications are also gladly maintained and kept alive. It is suggested if you should perform a new update for certain libraries/plugins so that the application continues to work properly.",
            "We can offer you the following models for cooperation:": "We can offer you the following models for cooperation.",
            "A work contract: Payment is based on results.": "A work contract: Payment is based on results.",
            "Hourly rate: An amount is agreed on how much € per hour is billed for the work.": "Hourly rate: An amount is agreed on how much € per hour is billed for the work.",
            "Hourly rate with bonus model: A fixed hourly rate is selected, with a bonus model if the results match.": "Hourly rate with bonus model: A fixed hourly rate is selected, with a bonus model if the results match.",
            "What services do we offer you?": "What services do we offer you?",
            //references
            "countbirdText": `During the Corona period, it was necessary for companies to know how many people were in a room. Using sensors and Android displays, you could see in entrances how many people were in the room. The display could be configured using a portal and, for example, a threshold could be set where the screen would turn red if too many people had entered the room. A visitor was recorded using sensors and also stored in the database and this data could be analysed in the portal.
                                Full-stack software development. Requirements provided by CEO and customer and implemented or discussed in the development team`
            ,
            "duaaText": "This is a homepage about Duaa Rasho's written books. The design is based on material kit react. Using Material Design guidelines. This app was created with the static html generator gatsby. Replaced Create React app with gatsby to load resources on build which improves performance. It also improves SEO.",
            "cleanbirdText": `You can manage your cleaning staff with an admin panel. You can schedule cleaners so they know when and where to clean.
            The cleaning staff receive a push notification when cleaning is due.
            In tables you can see when the cleaning forces have done their work. You can filter which cleaning surfaces (filtered by name) they have cleaned and which tasks have been completed.
            With Checkbird, part of the Cleanbird app, you could manage the cleaning tasks of the cleaning staff. With the Cleanbird device, the cleaners could enter their tasks, which could then be seen in the table for the manager.
            With Toolbird you can see where the Cleanbird devices are located.`,
            "lawyerText": "Further development and advice on further projects. Lecture and advice on which frontend technology to use including ReactJS VS Angular lecture.",
            "trainText": `Komm zum Zug is a website about jobs in Zug companies. People who are interested in a job can apply for a job on this homepage. They can look at the details of a job and can easily find out if a job suits them. You can also see where the location of the job is and from which company, all displayed in one map. Link: https://komm-zum-zug.at/`,
            "bikeText": `Web application: Radelt.at. In this app you can note how many kilometers you have ridden by bike. There are events where organizations can register and participate. At the beginning you set up a profile and with it a user registration. You can set what kind of cyclist you are (professional, beginner, advanced). There is a ranking where you can find out who rides the bike the most. It was programmed in Laravel PHP, which uses the MVC design pattern. The app is managed by a content management system called October. An admin interface was also produced using laravel nova. Here you could manage the users who were registered. So I gained experience how to maintain complex MySQL databases. Link: Radelt.at`,
            "doctorText": "Doctors can log in to this website with a code and see where and when an event starts. Through Google Maps he sees where the place is. The doctor also has to fill out a form in order to register for an event. After the form has been completed, an email will be sent to the organizer with the doctor's details. The doctor receives a confirmation email that he has successfully registered for an event. Link: http://patientimfokus.msd.at/",
            "cheapText": "In this application you can easily get the cheapest price of a component in different stores. The app also tells you whether it is cheaper to go to the shop yourself, order the goods online or drive to the shop yourself. With the data crawler I was able to get the data from the different shops.",
            "gitDashText": "Shows live statistics about the commits, pushes, merges or comments made by a developer. By using Angular Charts, the Git statistics were displayed very clearly and professionally on one page. When a developer made a commit, the app received a notification from Git, which caused the app to display the details of the commit for a few seconds and then navigate back to the stats.",
            "crmText": "The app had to be converted to AngularJS. A .NET API interface had to be set up parallel to the ASP.Net MVC controller. In the app you could manage the employees. You could see what they had to do, what position they were in, which customer was being looked after by which employees.",
            "authClientText": "The app contains statistics and tables, e.g. how many transactions were made each day, overview of all company transactions, how high the amounts were, how high for the entire day, etc. You can carry out transactions yourself. The backend models are represented on the view by AngularJS properties. Two-way data binding is used. If there was input from the user, the properties were adjusted and all models that were transferred in JSON could be saved on the backend.",
            "shakeMeChatText": "Development of a mobile messaging app. Creation of a massively scalable Erlang server. API requests from the client are created in extra threads so that the main thread controlling the UI does not freeze. Administration of a Postgre database, which the Erlang server accessed via an interface. Also included was the local database in Android, which allows caching and storing data permanently in the Android system.",
            "archetypeText": `
            o Hosting of the homepage in Wordpress
            o Performance / caching redis
            o Design, installation of the WordPress template and plugins
            o Adjustments of various elements, logo texts, photos
            `,
            "volkswagenText": `o Analyzing the code in ASP.NET C# / Javascript (bug fixing, refactoring, configuration)
            o Azure Dev Ops / Pipelines / Git Repos / Boards, Deployment => Azure App Service
            o Azure App Service Configuration, Fast already deployed services fixes with Kudu
            o Azure Application Insights for analysis, logs, performance checks
            o Management of dependencies (nugget packages, layers)
            o New UnitTest,s to check if code changes are correct
            o Health checks to see if web services, APIS, interfaces, databases are available.
            o Creation of Powershell Scripts (.ps1) integrated into Azure Dev Ops Pipelines. E.g. for scheduler and file management.
            o Microsoft Teams as a communication tool
            `,
            "cmnText": `DN-Solutions did the whole development process from scratch. From doing ongoing UI/UX, Performance, Readability improvements to develop new everongoing features. 
            For more information visit:
            Link: https://check-me-now.com`,
            "variocubeText": `
            Support for the development of smart locker systems software
            For more information visit: www.variocube.com
        `,
            "Projects Thus Far": "Projects Thus Far",
            "Project Duration": "Project Duration",
            "Technology": "Technology",
            "Company": "Company",
            "Closed": "Closed",
            "Decommissioned, was not completed due to change of plan": "Decommissioned, was not completed due to change of plan",
            "Support ongoing": "Support ongoing",
            "In Maintenance": "In Maintenance",
            "In Development": "In Development",
            //blog
            "Created": "Created",
            "To the application": "To the application",
            "to the code": "to the code",
            "reactSyntaxText": `This is a quick and easy way to display code snippets. Can often be seen in the blog when code is displayed`,
            "reactSpringText": `Animations in ReactJS. This library makes it easy to start animations. Complex animations are also possible. There are many setting options, e.g. the duration and the transformation animations are all supported.`,
            "skeletonText": `With skeletons you can make the user believe that everything is already loaded, even though everything has yet to finish loading in the background. This allows the homepage to appear faster. Large companies like Youtube use this method in their web application.`,
            //footer
            "Imprint": "Imprint",
            "Usage Service": "Usage Service",
            "Data Protection": "Data Protection",
            "language": "Language",
        }
    }
}